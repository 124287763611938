import 'animate.css/animate.css';

//import * as headerStyles from '../styles/common.module.css';
import loadable from '@loadable/component';
import { graphql, Link } from 'gatsby';
import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap'; // , Container
import Rellax from 'rellax';
import ScrollOut from 'scroll-out';

import AddressSearch from '../components/addressPartners';
import AddressSearchLife from '../components/addressPartnersLife';
import MenuLowrateco from '../components/menu-lowrateco';
import SEO from '../components/seo';
import bubbleemailer from '../images/bubble-emailer-final-3.png';
import bubbleemailersmall from '../images/bubble-emailer-small.png';
import facebook from '../images/facebook.svg';
import instagram from '../images/instagram.png';
import linkedin from '../images/linked-in.svg';
import LowRateCoLogo from '../images/LowRateCo-Logo.png';
import oval1 from '../images/oval1.svg';
import oval2 from '../images/oval2.svg';
import oval3 from '../images/oval3.svg';
import oval4 from '../images/oval4.svg';
// import smallCircle from "../images/smallCircle.svg";
import twitter from '../images/twitter.svg';
import * as headerStyles from '../styles/common.module.css';
import VerticalSlider from './VerticalSlider';

const Bubbletonerbanner = 'https://cdn.getmybubble.com/images/corporate-sites/Bubble_Emailer.webp';

import BubbleTestimony from '../components/BubbleTestimony';
import FooterCommon from '../components/footer-common';
import InsuranceLearnMore from '../components/InsuranceLearnMore';
const HomePageBlogs = loadable( () => import( '../components/homePageBlogs' ) );


export const pageQuery = graphql`
query {
  allStrapiArticle(filter: {page_name: {eq: "home_page"}, status: {eq: "Production"}, data_category: {eq: "did_you_know"}}) {
    edges {
      node {
        body
        id
        title
      }
    }
  }
  licenseNumbers: allStrapiPageData(filter: {page_name: {eq: "license_numbers"}}) {
    edges {
      node {
        data_category
        column
        content
        style_category
        attribute_1_value
        attribute_2_value
        attribute_1_key
        attribute_2_key
      }
    }
  }
  allStrapiUser {
    edges {
      node {
        email
        username
        role {
          name
        }
      }
    }
  }
  allStrapiPost(limit: 3, sort: {fields: published_at, order: DESC} , filter:{category: {eq : "BLOG"}}) {
    edges {
      node {
        id
        body
        category
        className
        created_at
        image{
          publicURL
        }
        published_at
        title
        strapiId
        id
      }
    }
  }

  allStrapiPageData(filter: {page_name: {eq: "partner_logo"}}) {
    edges {
      node {
        id
        page_name
        style_category
        content
        attribute_1_key
        attribute_1_value
        attribute_2_key
        attribute_2_value
        column
        data_category
        version
      }
  }
}
}
`;

const blogData = [
  { type: 'Life Insurance', color: '#0d4d90', background: 'rgba(13, 77, 144, 0.1)' },
  { type: 'Others', color: '#5e5e5e', background: 'rgba(207, 207, 207, 0.3)' },
  { type: 'Home Insurance', color: '#9375ff', background: 'rgba(147, 117, 255, 0.1)' }
];

const IndexPage = ( { data } ) => {

  useEffect( () => {
    new Rellax( '.rellax', { // <---- Via class name
      speed: 5
    } );
  }, [] );

  useEffect( () => {
    ScrollOut( {
      threshold: .5,
      once: false,
      cssProps: {
        viewportY: true,
        visibleY: true
      }
    } );
  }, [] );


  return (
    <div>

      <SEO
        title="Bundled Home and Life"
        description="Digitally bundled life and home insurance for homeowners to protect their homes and their loved ones! Visit now to see how little it costs."
        keywords="Bundled Insurance Plans Life and Home Insurance Best Bundle Insurance"
      />

      <script>
        var rellax = new Rellax('.rellax');
        console.log('Hello from rellax')
        ScrollOut();
      </script>

      {/* <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB_DcLM8ITEURJDmtF5T8CQrQBCOqkCc3o&fields=formatted_address,places&libraries=places">

      </script> */}

      <MenuLowrateco/>

      <div  className="wrapper">
        {/* banner section */}

        <div className={headerStyles.mainBannerWrapper + ' toner-header1'}>
          <div className="container-fluid">

            <div className="row align-items-center justify-content-center d-flex">

              <div className="banner-left-bar pt-0 pb-0 text-center ">
                <img src={Bubbletonerbanner} alt="Toner" className="img-fluid"/>
              </div>

              <div className="banner-right-bar">
                <div className="pt-0 pb-0 banner-text">
                  <h2 className={headerStyles.hpMainHeader} data-scroll> Hi there!  <div> Low Rate Co</div> has partnered with Bubble to offer great online home and life insurance options for its customers</h2>

                  <h4 className={headerStyles.hpSecondHeader} data-scroll>Smart Insurance. Total Assurance.</h4>
                </div>

                <div className="navsection">
                  <Tabs defaultActiveKey="homeandlife" id="controlled-tab-example">
                    <Tab eventKey="homeandlife" title="Home and Life">
                      <div className="col-12 pt-0 pb-0 text-center">
                        <AddressSearch insuranceType='homeLife' parentPage="Low Rate Co." licenseState={data.licenseNumbers.edges} />
                      </div>
                    </Tab>

                    <Tab eventKey="home" title="Home">
                      <div className="col-12 pt-0 pb-0 text-center">
                        <AddressSearch insuranceType='home' parentPage="Low Rate Co." licenseState={data.licenseNumbers.edges} />
                      </div>
                    </Tab>

                    <Tab eventKey="life" title="Life">
                      <div className="col-12 pt-0 pb-0 text-center">
                        <AddressSearchLife parentPage="Low Rate Co." insuranceType='life' />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>

            </div>

          </div>
        </div>


        {/* second part div struct */}

        <div id="scrollDiv" className="vertical-slidebox">
          <div className="container common-container-space">
            <div className="row">
              <div className="col-12 text-center">
                <h2 className="slide-head" data-scroll> See why Home and <br />Life Insurance belong together</h2>
              </div>
            </div>
          </div>

          <VerticalSlider allStrapiArticle={data.allStrapiArticle.edges} />
        </div>

        {/* third part div struct */}

        <InsuranceLearnMore/>


        <div className="toner-getin-touch-section">
          <div className="container">
            <div className="toner-getin-touch-box">
              <div className="toner-getin-touch-innerbox1">
                <img src={LowRateCoLogo} alt=""></img>

                <div>833-499-7788</div>

                <div>Info@LowRateCo.com</div>

                <ul>
                  <li> <a href="https://www.facebook.com/RealFlyhomes/" target="_blank" rel="noreferrer"><img src={facebook} alt="fb"/></a></li>

                  <li> <a href="https://www.instagram.com/realflyhomes/" target="_blank" rel="noreferrer"><img src={instagram} alt="insta"/></a></li>

                  <li> <a href="https://twitter.com/RealFlyHomes" target="_blank" rel="noreferrer"><img src={twitter} alt="twit"/></a></li>

                  <li> <a href="https://www.linkedin.com/company/flyhomes" target="_blank" rel="noreferrer"><img src={linkedin} alt="linkedin"/></a></li>

                </ul>

                <p>We don’t believe in charging buyers unnecessary commission and fees, the process of finding the home of your dreams can be long and trying. We truly believe that once you find it, financing should be the easy part. That’s why we created Low Rate Co. </p>

                <p>Pay for the home, not for the service. Whether you are a first-time home buyer, refinancing your home, or an experienced income property owner, we are here to provide financing options that fit your needs without setting you back.</p>

                <a href="mailto:Info@LowRateCo.com" className="getstart-btn">Get In Touch</a>
              </div>

              <div className="toner-getin-touch-innerbox2">
                <a><img src={bubbleemailer} alt="email" /></a>
              </div>
            </div>
          </div>


          <img src={oval1} alt="Toner" className="toner-bgbtleftsmall rellax" data-rellax-speed="2" />

          <img src={oval2} alt="Toner" className="toner-bgtoprtsmall rellax" data-rellax-speed="2" />

          <img src={oval3} alt="Toner" className="toner-bgbtrtsmall rellax" data-rellax-speed="2" />

          <img src={oval4} alt="Toner" className="toner-mobfirst rellax" data-rellax-speed="2" />


        </div>


        <div className="container">
          <div className="toner-getin-touch-section2">
            <div>
              <a><img src={bubbleemailersmall} alt="email"/></a>
            </div>

            <div>
              <h4>Got any questions?</h4>

              <p>For any insurance-related queries, get in touch and we'll help you</p>

              <Link to="/" className="getstart-btn">Get In Touch</Link>
            </div>
          </div>
        </div>


        <BubbleTestimony/>

        {/* <FooterLowrateco/> */}

        <FooterCommon>
          <p>Low Rate Co offers insurance policies through our partner at Bubble Insurance Solutions </p>
        </FooterCommon>
      </div>
    </div>
  );
};

export default IndexPage;
